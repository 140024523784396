@import url(https://fonts.googleapis.com/css2?family=Poppins);

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

a {
  text-decoration: none;
  color: inherit;
}

.btn-primary {
  background-color: #64b92a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 12px;
}
.btn-next {
  padding: 6px 12px;
  border: none;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 /60%);
  border-radius: 2px;
  color: #333333;
  background-color: #ffffff;
}
.btn-next:hover {
  background-color: rgb(216, 210, 210);
}

.createRouteTable {
  width: 80% !important;
  margin: 0 auto;
}

select {
  width: 100%;
  height: 50px;
  border-radius: 4px;
}

input {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 5px;
}
th {
  font-size: 15px;
}
td {
  font-size: 13px;
  color: #222222;
  line-height: 30px;
}
.bottom_line {
  border: 2px dashed rgb(189, 188, 188);
}
.tbl_mobile {
  font-size: 10px;
  color: #999999;
}
sup {
  color: red;
}
.dashed_line {
  border: 1px dashed rgb(192, 190, 190);
}
abbr {
  cursor: pointer;
}
.spn:hover {
  color: #084377;
  transition: all 0.2s linear;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* create host driver css  */
.inpClass {
  height: 40px !important;
  width: 60%;
}
.labelCls {
  width: 40%;
}
.red_clr {
  color: red;
}
.message_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: end;
  margin-bottom: 8%;
  margin-top: 5%;
}
.chatSetup {
  margin: 5px;
  border-radius: 11px;
  padding: 5px;
  width: 35%;
  font-size: large;
  color: white;
  font-weight: 200px;
}

.buttonData {
  margin-left: 1%;
  padding: 5px;
  border-radius: 11px;
  width: 7%;
  background-color: #002e6e;
  color: white;
}

.inputClass {
  margin-top: 4px;
  width: 92%;

  border-radius: 15px;
}

.navbar {
  overflow: hidden;

  position: fixed;
  bottom: 0;
  width: 77.5%;
}
.view_route_border {
  border: 1.5px solid rgb(134, 134, 134);
  /* width: 30%; */
  height: 35px;
  line-height: 35px;
  border-radius: 4px;
  padding: 0px 7px;
  z-index: 2;
}
.select_cls{
  height: 35px;
  line-height: 35px;
  z-index: -2;
}